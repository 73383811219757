import { useEffect } from "react";
import Hero from "../components/Hero/Hero";
const Home = () => {
  useEffect(() => {
    document.title = "ArtApps";
  }, []);
  return (
    <>
      <Hero />
    </>
  );
};

export default Home;