import React from "react";
import { Container } from "../../globalStyles";
import { HeroImg, HeroSection, HeroText } from "./HeroStyles";

const Hero = () => {
  return (
    <HeroSection>
      <Container>
        {/* <HeroImg src="/Users/adhamkamala/Documents/Projects/Pion/React/ad-03/src/components/Hero/ArtApps.png" /> */}
        <HeroText>Under Development...</HeroText>
      </Container>
    </HeroSection>
  );
};

export default Hero;
